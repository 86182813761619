import axios from "axios";

export const iparhaiServer = axios.create();
iparhaiServer.defaults.baseURL = "https://api.proctorparhai.com/";
// iparhaiServer.defaults.baseURL = "https://wz7rhtkb-8080.inc1.devtunnels.ms/";
// export  const iparhaiServer = axios.create();

// iparhaiServer.defaults.baseURL = "http://localhost:8080/";
// 
// 
