import { Backdrop, Box, Button, Fade, Modal } from '@mui/material';
import React from 'react'
import { Bars } from 'react-loader-spinner';
import PsychometricAccordion from '../Question accordion/PsychometricAccordion';

const PsychometricModal = ({
    open,
    setOpen,
    onClose,
    setViewReportModal,
    questionData,
    viewQuestionLoading,
    handleDownloadDocx,
    viewReport,
    test,
    userDetails,
    gradelevel,
    subject,
    enableRubric,
  }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <div className="h-full px-2 py-12">

              <>
                <div className="p-6 overflow-y-scroll h-[80%]">
                  {questionData.length > 0 &&
                    questionData.map((e, id) => {
                      return (
                        <PsychometricAccordion
                          key={id}
                          no={id}
                          questionID={e.id}
                          question={e.question}
                          answer={e.answer}
                          attribute={e.attribute}
                        />
                      );
                    })}
                  {viewQuestionLoading && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '540px',
                      }}
                    >
                      <Bars
                        height="80"
                        width="80"
                        color="#004e8f"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  )}
                </div>
              </>
              <div className="my-4 flex gap-10 justify-center absolute bottom-16 left-1/2 transform -translate-x-1/2">
              <Button
                variant="contained"
                onClick={()=>setOpen(false)}
              >
                Close
              </Button>
              </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}

export default PsychometricModal
