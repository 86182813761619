import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import React from 'react'

const PsychometricAccordion = ({ questionID,key,no, question,answer,attribute}) => {
  return (
    <Accordion key={no}>
    <AccordionSummary
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <div className="w-full flex justify-between">
        <Typography>
          <span className="font-semibold">QUESTION No. {++no}:</span>{" "}
          {question}
        </Typography>
        {/* <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg "><span className="font-medium">Total Marks: </span><span>{marks}</span>/<span className="text-[#004e8f] font-semibold">{totalMarks}</span></p> */}
      </div>
    </AccordionSummary>
    <AccordionDetails className="flex flex-col justify-between">
      <Typography sx={{
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
      }} className="">
        <span className="font-semibold">ANSWER: </span>
        {answer}
      </Typography>
      <Typography sx={{
        marginTop: "10px",
        marginLeft: "20px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
      }} className="">
        <span className="font-semibold">Attributes: </span>
        {attribute}
      </Typography>


    </AccordionDetails>
  </Accordion>
  )
}

export default PsychometricAccordion
