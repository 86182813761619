import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/Navigation/SideBar";

function Result() {
  const screenSwitches = useSelector((state) => state.proctorReducer.switches);
  const proctorcount = useSelector((state) => state.proctorcountReducer.proctorcount);
  const truecount = useSelector((state) => state.proctorcountReducer.trueCount);
  const percentage = (truecount / proctorcount) * 100;


  return (
    <>
      <>
        <Box
          height="40%"
          sx={{
            position: "relative",
            bgcolor: "#F8F8F8",
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              display: "flex",
            }}
          >
            <Grid item xs={0} sm={3}>
              <Sidebar />
            </Grid>
            <div
              className="flex justify-center items-center mx-auto"
            >
              <Paper elevation={5} sx={{ p: 4, textAlign: "center" }}>
                <Typography fontWeight={800} fontSize={20}>Your Test Has Been Submitted!! ✔️</Typography>
                <Typography>Your Score </Typography>
                <Typography>
                  Your result and invigilation report have been sent to admin 
                </Typography>
                <Typography>Best Of Luck</Typography>
              </Paper>
            </div>

          </Grid>
        </Box>
      </>


    </>
  );
}

export default Result;
