import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Input,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { InfoOutlined } from "@mui/icons-material";
import { readFromExcel } from "../../utils/readfromexcel";
import { downloadFile } from "../../utils/micsfun";


const QuestionAccordion = ({ no, questionID, question, answer, totalMarks, gradelevel, subject, autoGradedMarks, autoGradedReason, enableRubric }) => {
  const [reason, setReason] = useState(autoGradedReason);
  const [marks, setMarks] = useState(autoGradedMarks);
  const [loading, setLoading] = useState(false);
  const [rubrics, setRubrics] = useState("");

  const handleAutoGrading = async (q, a, m, g) => {
    try {
      setLoading(true);
      const res = await iparhaiServer.post("/api/question/gradeanswer", {
        question: q,
        questionID,
        answer: a,
        subject,
        total_marks: m,
        grade: g,
        is_reason: true,
        conditions: null,
        rubrics: rubrics,
        isRubrics: enableRubric,
      });
      setRubrics("");
      setLoading(false);

      setMarks(res.data.marks);

      let formattedResponse = res.data.reason.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
      setReason(formattedResponse.slice(1, formattedResponse.length - 2));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleReadFromExcel = async (e) => {
    const file = e.target.files[0];
    let data = await readFromExcel(file);

    let formattedString = "";

    data.forEach((row) => {
      for (let key in row) {
        formattedString += `${key}: ${row[key]},`;
      }
    });

    setRubrics(formattedString);
  };

  return (
    <Accordion key={no}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <div className="w-full flex justify-between">
          <Typography>
            <span className="font-semibold">QUESTION No. {++no}:</span> {question}
          </Typography>
          <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg ">
            <span className="font-medium">Total Marks: </span>
            <span>{marks}</span>/<span className="text-[#004e8f] font-semibold">{totalMarks}</span>
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails className="flex flex-col justify-between">
        <Typography
          sx={{
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          }}
        >
          <span className="font-semibold">ANSWER: </span>
          {answer}
        </Typography>

        {!reason && (
          <div className="flex flex-col items-center h-fit bg-slate-100 rounded-lg px-10 py-2">
            {enableRubric && (
              <>
                <p className="text-sm">
                  You have enabled rubrics for this test. Please upload the rubric file first.
                </p>
                <div className="flex space-x-6">
                  <label className="text-sm" htmlFor="excelFileInput">
                    <Button
                      size="small"
                      className="text-sm"
                      variant="contained"
                      component="span"
                      color={rubrics.length > 0 ? "error" : "primary"}
                      endIcon={
                        <Tooltip title="Detail" placement="top">
                          <InfoOutlined />
                        </Tooltip>
                      }
                    >
                      {rubrics.length > 0 ? "Change File" : "Upload Excel File"}
                    </Button>
                  </label>
                  <Input
                    accept=".xlsx, .xls"
                    id="excelFileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleReadFromExcel}
                    inputProps={{
                      onClick: (event) => (event.target.value = null),
                    }}
                  />
                  <Button
                    onClick={() => downloadFile("rubricTemplate.xlsx")}
                    variant="outlined"
                  >
                    Download Template
                  </Button>
                </div>
              </>
            )}
            {(enableRubric && rubrics.length > 0) || (!enableRubric && !marks) ? (
              <Button
                variant="outlined"
                sx={{
                  width: "142px",
                  height: "35px",
                  p: 0,
                }}
                onClick={() => handleAutoGrading(question, answer, totalMarks, gradelevel)}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Auto Grading"
                )}
              </Button>
            ) : null}
          </div>
        )}
        {reason && (
          <div>
            <code className="font-semibold">Marks:</code>
            <span
              className="px-4 py-2 rounded-lg"
              dangerouslySetInnerHTML={{ __html: reason }}
            />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionAccordion;
