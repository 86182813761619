import jsPDF from "jspdf";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

const viewReport = async (row, test, setPdfDataUrl, setViewReportModal) => {
  const userId = typeof row === "object" ? row.id : row;
  const user = test.scores.find((score) => score.userId === userId);
  const pdf = new jsPDF();
  const logo = require("../assets/logosyslab.jpg");

  // Add logo
  pdf.addImage(logo, "JPEG", 10, 10, 50, 20);
  const pageWidth = pdf.internal.pageSize.getWidth();
  const imageWidth = 50;

  // Add user image
  if (user.encoding && test.test.enableAIVigilance) {
    pdf.addImage(
      user.encoding,
      "JPEG",
      pageWidth - imageWidth - 10,
      10,
      imageWidth,
      50
    );
  }

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(10);
  pdf.text(
    `Date: ${formatDate(test.test.startDate)}\nTest Title: ${
      test.test.name
    }\nCandidate Name: ${user.user.name}\nOrganization Name: ProctorParhai`,
    20,
    55
  );

  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(12);

  const availableSpace = pdf.internal.pageSize.height - 90;
  let yPos = 90;

  // Function to handle adding text and checking for page overflow
  const addTextToPdf = (text, x, y) => {
    const lines = pdf.splitTextToSize(text, 160);
    const spaceNeeded = lines.length * 5 + 5;
    if (y + spaceNeeded > availableSpace) {
      pdf.addPage();
      y = 20;
    }
    pdf.text(lines, x, y);
    return y + spaceNeeded;
  };

  // Test score information
  if (test.test.assessmentType === "MCQ-based") {
    const marksString = row.marks; // Example: '50/100'
    const [marksObtained, totalMarks] = marksString.split("/").map(Number);
    const percentage = ((marksObtained / totalMarks) * 100).toFixed(2); // Percentage with 2 decimal places

    const scoreInfo = `A Test was conducted as per the above data. The candidate scored ${marksObtained} Marks (${percentage}%) in the test.`;
    yPos = addTextToPdf(scoreInfo, 20, yPos);
  }

  // Facial recognition information
  if (user.similarityscore != null) {
    const facialRecognition = `Using our AI vigilance system, we have detected that facial score matched is ${user.similarityscore} as aligning with ProctorParhai's standards. Below are objects and their counts observed during the invigilance.`;
    const faceDetectionText =
      user.noface > 0
        ? ` and ${user.noface} times face wasn't detected according to the image displayed on the report.`
        : "";
    yPos = addTextToPdf(`${facialRecognition}${faceDetectionText}`, 20, yPos);
  }

  // Objects information
  if (user.objects) {
    const objectsInfo = Object.entries(user.objects);
    objectsInfo.forEach(([key, value]) => {
      const objectText = `${key}: ${value}`;
      yPos = addTextToPdf(objectText, 20, yPos);
    });
  }

  // Tab switch information
  const tabSwitchText = `During the test, the user switched tab ${user.tab_switch} times.`;
  yPos = addTextToPdf(tabSwitchText, 20, yPos);

  // Noise detection information
  if (test.test.enableAIVigilance && user.audio.trim()) {
    const noiseDetectionText = `Voice was detected: ${user.audio.trim()}`;
    yPos = addTextToPdf(noiseDetectionText, 20, yPos);
  }
  if (test.test.enableAIVigilance && user.faceCount) {
    const faceCountText = `Face count: ${user.faceCount}`;
    yPos = addTextToPdf(faceCountText, 20, yPos);
  }

  // Gaze information
  if (test.test.enableAIVigilance) {
    const gazeText = `During the test, the user has seen ${
      user.centerGaze || 0
    } times Center and ${user.notCenterGaze || 0} times not in center.`;
    yPos = addTextToPdf(gazeText, 20, yPos);
  }

  // Contact information
  yPos = addTextToPdf(
    "If you have any questions, please feel free to contact the ProctorParhai Team",
    20,
    yPos
  );
  yPos = addTextToPdf("info@proctorParhai.com", 20, yPos);

  // Generate the PDF as a data URL
  const pdfDataUrl = pdf.output("dataurlstring");

  // Set the PDF data URL in the state
  setPdfDataUrl(pdfDataUrl);
  setViewReportModal(true);
};

const handleDownloadDocx = async (userDetails, questionData) => {
  // console.log(userDetails);
  try {
    const paragraphs = [];
    paragraphs[0] = new Paragraph({
      children: [
        new TextRun({ text: "Username: ", bold: true }),
        new TextRun(`${userDetails.name}`),
        new TextRun({ break: true }),
        new TextRun({ text: "Email: ", bold: true }),
        new TextRun(`${userDetails.email}`),
        new TextRun({ break: true }),
        new TextRun({ break: true }),
      ],
    });
    // Iterate over data and create paragraphs
    questionData.forEach(({ question, answer }, index) => {
      paragraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              text: `Question ${index + 1}:  `,
              bold: true,
              break: true,
            }),
            new TextRun({ text: `${question}`, bold: "semiBold" }),
            new TextRun({ break: true }), // Insert line break
            new TextRun("Answer: "),
            new TextRun(answer),
          ],
        })
      );
    });

    // Create a new Document
    const doc = new Document({
      sections: [
        {
          children: paragraphs, // Add the generated paragraphs
        },
      ],
    });

    // Create a blob from the document and download it
    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${userDetails.email}.docx`);
  } catch (error) {
    console.error("Error creating Word document:", error);
  }
};

const formatDate = (
  date,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const newDate = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: timeZone,
  };
  return new Intl.DateTimeFormat("en-US", options).format(newDate);
};

const handleGenerateReport = (row, test) => {
  const user = test.scores.find((score) => score.userId === row.id);
  const pdf = new jsPDF();
  const logo = require("../assets/logosyslab.jpg");

  pdf.addImage(logo, "JPEG", 10, 10, 50, 20);

  const pageWidth = pdf.internal.pageSize.getWidth();
  const imageWidth = 50; // Width of the image

  // Position the base64 image to the right
  if (user.encoding && test.test.enableAIVigilance) {
    pdf.addImage(
      user.encoding,
      "JPEG",
      pageWidth - imageWidth - 10,
      10,
      imageWidth,
      50
    );
  }

  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(10);
  pdf.text(
    `Date: ${formatDate(test.test.startDate)}\nTest Title: ${
      test.test.name
    }\nCandidate Name: ${row.name}\nOrganization Name: ProctorParhai`,
    20,
    55
  );

  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(12);

  const availableSpace = pdf.internal.pageSize.height - 90;
  let yPos = 90;

  // Function to handle adding text and checking for page overflow
  const addTextToPdf = (text, x, y) => {
    const lines = pdf.splitTextToSize(text, 160);
    const spaceNeeded = lines.length * 5 + 5;
    if (y + spaceNeeded > availableSpace) {
      pdf.addPage();
      y = 20;
    }
    pdf.text(lines, x, y);
    return y + spaceNeeded;
  };

  // Test score information
  if (test.test.assessmentType === "MCQ-based") {
    const marksString = row.marks; // Example: '50/100'
    const [marksObtained, totalMarks] = marksString.split("/").map(Number);
    const percentage = ((marksObtained / totalMarks) * 100).toFixed(2);

    const scoreInfo = `A Test was conducted as per the above data. The candidate scored ${marksObtained} Marks (${percentage}%) in the test.`;
    yPos = addTextToPdf(scoreInfo, 20, yPos);
  } // Percentage with 2 decimal places

  // Facial recognition information
  if (user.similarityscore != null) {
    const facialRecognition = `Using our AI vigilance system, we have detected that facial score matched is ${user.similarityscore} as aligning with ProctorParhai's standards. Below are objects and their counts observed during the invigilance.`;
    const faceDetectionText =
      user.noface > 0
        ? ` and ${user.noface} times face wasn't detected according to the image displayed on the report.`
        : "";
    const fullFacialRecognition = `${facialRecognition}${faceDetectionText}`;
    yPos = addTextToPdf(fullFacialRecognition, 20, yPos);
  }

  // Objects information
  if (user.objects) {
    const objectsInfo = Object.entries(user.objects);
    objectsInfo.forEach(([key, value]) => {
      const objectText = `${key}: ${value}`;
      yPos = addTextToPdf(objectText, 20, yPos);
    });
  }

  // Tab switch information
  const tabSwitchText = `During the test, the user switched tab ${user.tab_switch} times.`;
  yPos = addTextToPdf(tabSwitchText, 20, yPos);

  // Noise detection information
  if (test.test.enableAIVigilance && user.audio) {
    const noiseDetectionText = `Voice was detected: ${user.audio.trim()}`;
    yPos = addTextToPdf(noiseDetectionText, 20, yPos);
  }
  if (test.test.enableAIVigilance && user.faceCount) {
    const faceCountText = `Face count: ${user.faceCount}`;
    yPos = addTextToPdf(faceCountText, 20, yPos);
  }

  // Gaze information
  if (test.test.enableAIVigilance) {
    const gazeText = `During the test, the user has seen ${
      user.centerGaze || 0
    } times Center and ${user.notCenterGaze || 0} times not in center.`;
    yPos = addTextToPdf(gazeText, 20, yPos);
  }

  // Contact information
  yPos = addTextToPdf(
    "If you have any questions, please feel free to contact the ProctorParhai Team",
    20,
    yPos
  );
  yPos = addTextToPdf("info@proctorParhai.com", 20, yPos);

  pdf.save(`${user.user.name}_report.pdf`);
};

export { handleGenerateReport, formatDate, viewReport, handleDownloadDocx };
